import React from "react"
import {
  IconDashboard,
  IconDownloads,
  IconEquipping,
  IconModes,
  IconModules,
  IconSettings,
  IItemSidebarNavigation,
  ILocale,
} from "shared"

import { routePaths } from "../_routes.constants"

/**
 * Константы пунктов меню (боковая панель)
 */
export function getItemSidebarNavigation(l: ILocale): IItemSidebarNavigation {
  return [
    {
      path: routePaths.DASHBOARD,
      label: l.equipment.dashboard,
      icon: (isDark) => (isDark ? <IconDashboard isDark /> : <IconDashboard />),
      description:
        "Дашборд системы. Удобный контроль за текущим состоянием оборудования и уведомлениями об обнаруженных нарушениях режимов работы.",
    },
    {
      path: routePaths.EQUIPMENTS,
      label: l.equipment.equipment,
      icon: (isDark) => (isDark ? <IconEquipping isDark /> : <IconEquipping />),
      description:
        "Журнал оборудования. Наиболее полная информация о добавленном в систему оборудовании и добавление новых позиций.",
    },
    {
      path: routePaths.MODULES,
      label: l.echoModule.modules,
      icon: (isDark) => (isDark ? <IconModules isDark /> : <IconModules />),
      description:
        "Журнал модулей Echo. Добавление новых модулей и контроль показаний датчиков, подключенных к текущим устройствам.",
    },
    {
      path: routePaths.NOTIFICATIONS,
      label: l.notification.notifications,
      icon: (isDark) => (isDark ? <IconModes isDark /> : <IconModes />),
      description: "Журнал уведомлений. Перечень всех уведомлений, полученных от системы.",
    },
    {
      path: routePaths.MODES,
      label: "Ремонты",
      icon: (isDark) => (isDark ? <IconModes isDark /> : <IconModes />),
    },
    {
      path: routePaths.DOWNLOADS,
      label: l.downloads.downloads,
      icon: (isDark) => (isDark ? <IconDownloads isDark /> : <IconDownloads />),
      description: "Журнал загрузки. Таблица для загрузки и экспорта данных из системы.",
    },
    //   {
    //     path: routePaths.SETTINGS,
    //     label: "Настройки",
    //     icon: (isDark) => (isDark ? <IconSettings isDark /> : <IconSettings />),
    //     description:
    //       "Меню настроек системы. Установка текущего времени, интеграция со сторонними базами данных и управление параметрами доступа.",
    //   },
  ]
}
